function App() {
  return (
    <div className="App">
      <div
        className="flex flex-col justify-center items-center h-screen"
        style={{ backgroundColor: '#868dcd', color: 'white' }}
      >
        <img
          src="LogoEng.png"
          alt="Coming Soon"
          className="mb-8 w-[800px] max-w-full"
        />
        <h1 className="text-8xl text-center font-bold mb-4">Coming Soon</h1>
        <p className="text-lg text-center">
          We're working hard to bring something amazing! Stay tuned.
        </p>
      </div>
    </div>
  );
}

export default App;
